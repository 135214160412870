export const colors = {
  // Colors
  // primary: "#35404C",
  white: "#FFFFFF",
  liteGrey: "#F9F9F9",
  blue: "#0082df",
  red: "#ff3f00",

  // Text
  liteText: "#DAD1D4",
  text: "#999999",
  darkText: "#5c6e75",

  // Backgrounds
  background: "#35404C",
  darkBackground: "#1E1F20",

  // Border
  border: "#EDEDED",

  // Status
  success: "#2AD955",
  error: "#FF6D26",
};

export const fonts = {
  base:
    '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"'
};

export const sizes = {
  xlarge: 1280,
  large: 1024,
  medium: 768,
  small: 568,
};

const theme = {
  colors,
  fonts,
  sizes,
};

export default theme;
