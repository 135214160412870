import { createGlobalStyle } from "styled-components";
import { media } from "./utils/breakpoint";
import theme from "./theme";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    font-size: 12px;
    overflow-x: hidden;

    ${media.small`
      font-size: 14px;
    `}
    ${media.large`
      font-size: 16px;
    `}
  }

  body {
    height: 100vh;
    color: ${theme.colors.darkText};
    background: ${theme.colors.background};
    font-family: ${theme.fonts.base};
    font-weight: 300;
    overflow-x: hidden;
  }

  strong {
    font-weight: 600;
  }
`;

export default GlobalStyles;
