import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";

const H1 = styled.h1`
  margin: 10rem auto;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
  color: rgba(37, 45, 55, 0.9);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.1);
`;

const IndexPage = () => (
  <Layout>
    <SEO
      title="Pilande"
      keywords={[`gatsby`, `application`, `react`]}
      description="Hello World"
    />
    <H1>pilande</H1>
  </Layout>
);

export default IndexPage;
